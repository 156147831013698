<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="daily-cases-available-cases">
    <template v-if="userInfo.availableCases?.length && userStore.user?.wasGeneratedLvl">
      <div :class="['daily-cases-available-cases__container', casesContainerClasses]">
        <div
          v-for="(item, id) in userInfo.availableCases"
          :key="id"
          class="daily-cases-available-cases__image-container"
        >
          <UiImage
            v-if="dailyCaseStore.getImagePath(item.id)"
            class="daily-cases-available-cases__image"
            :src="dailyCaseStore.getImagePath(item.id)"
          />
        </div>
      </div>
      <div class="daily-cases-available-cases__subtext">
        {{ userInfo.availableCases?.length }} {{ availableCasesDeclination }}
      </div>
    </template>
    <template v-else>
      <div class="daily-cases-available-cases__block">
        <template v-if="userInfo.userLevel && userStore.user?.wasGeneratedLvl">
          <p v-if="+userInfo.userLevel > 0 && refinedTime">
            {{ $t('mainPage.dailyCasesAvailableCases.cooldown') }}
            <span class="daily-cases-available-cases__block_highlighted">
              {{ timerData.hours }}:{{ timerData.minutes }}:{{ timerData.seconds }}
            </span>
          </p>
          <I18nT keypath="mainPage.dailyCasesAvailableCases.moreExp" tag="p">
            <template #exp>
              <span class="daily-cases-available-cases__block_highlighted">{{ nextLvlCaseData.exp }}</span>
            </template>
            <template #link>
              <NuxtLinkLocale class="daily-cases-available-cases__block_link" :to="nextLvlCaseData.link">
                {{ nextLvlCaseData.text }}
              </NuxtLinkLocale>
            </template>
          </I18nT>
        </template>
        <template v-else>
          <p>{{ $t('mainPage.dailyCasesAvailableCases.noLevel1') }}</p>
          <p>{{ $t('mainPage.dailyCasesAvailableCases.noLevel2') }}</p>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { IDailyCasesAvailableCasesProps } from './DailyCasesAvailableCases.types';
import { useUserStore } from '~/store/user/user.store';
import { useDailyCasesStore } from '~/store/dailyCases/dailyCases.store';

const { t } = useI18n();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const props = defineProps<IDailyCasesAvailableCasesProps>();
const { userInfo } = toRefs(props);
const userStore = useUserStore();
const dailyCaseStore = useDailyCasesStore();
const { dailyCases } = storeToRefs(dailyCaseStore);

const refinedTime = ref(0);
const { timerData, start } = useTimer(refinedTime);

onBeforeMount(() => {
  watch(
    userInfo,
    (value) => {
      refinedTime.value = value?.nearestCaseTime ?? 0;
      start();
    },
    { deep: true, immediate: true },
  );
});

const casesContainerClasses = computed(() => ({
  '--couple-items': (userInfo.value?.availableCases ?? []).length < changeXL(4, 5),
}));

const dailyCaseImageWidth = computed(() => {
  return GlobalUtils.CSS.proceedCssValue(changeXL(72, 48), 'px');
});

const availableCasesDeclination = computed(() => {
  const cases = t('freeCases.cases', { count: userInfo.value.availableCases?.length });
  const casesAvailable = t('freeCases.casesAvailable', { count: userInfo.value.availableCases?.length });
  return `${cases} ${casesAvailable}`;
});

const nextLvlCaseData = computed<Record<string, string>>(() => {
  const nextDailyCase = dailyCases.value.find((item) => Number(item?.level) >= Number(userInfo.value.userLevel));
  if (!nextDailyCase) return { text: '', link: '', exp: '' };

  const { level, link, exp } = nextDailyCase;

  return {
    text: t('mainPage.dailyCasesAvailableCases.nextLvlCase', { level }),
    link: `${useLinks().OPEN_CASE}${link}`,
    exp: `${exp}`,
  };
});
</script>

<style scoped lang="scss">
.daily-cases-available-cases {
  --daily-case-image-width: v-bind(dailyCaseImageWidth);
}
</style>

<style scoped lang="scss" src="./DailyCasesAvailableCases.scss"></style>
